header {
    background-color: #6c6c6c;
    height: 60px;
    margin-bottom: 20px;
    padding: 20px ;
  }
  
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    height: 100%;
  }
  
  li {
    margin: 0 10px;
  }
  
  a {
    color: #fff;
    text-decoration: none;
    padding: 10px;
    border-radius: 5px;
  }
  
  a:hover {
    background-color: #555;
  }



  header div {
    color: #ffffff;
    font-size: 20px;
    padding-left: 90px;
  }

  .logout {
    margin-left: 30px;
  }
  