

.welcome-screen {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  opacity: 1; 
  transition: opacity 800ms cubic-bezier(0.68, -0.55, 0.27, 1.55), transform 800ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.fade-enter {
  opacity: 0;
  transform: translateY(100%);
}

.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 800ms cubic-bezier(0.68, -0.55, 0.27, 1.55), transform 800ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
}

.fade-exit {
  opacity: 1;
  transform: translateY(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(100%);
  transition: opacity 800ms cubic-bezier(0.68, -0.55, 0.27, 1.55), transform 800ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
